.header-container {
  padding: 1.5% 9% 0;
  position: fixed;
    z-index: 1;
    background: #fff;
    width: 100%;
}
.mobile-menu{
  display: none;
}
.header-logo {
  display: inline-block;
}
.header-menus {
  float: right;
}
.header-menus li {
  display: inline-block;
  list-style: none;
  margin-right: 41px;
  margin-top: 16px;
  height: 55px;
  width: 77px;
  text-align: center;
}
.header-menus li:last-child {
  margin-right: 0;
}
.header-menus li a {
  text-decoration: none;
  color: #394149;
  font-weight: bold;
}
.header-menus li.active-menu {
  border-bottom: 3px solid #272db0;
}
.header-menus li.active-menu a {
  color: #272db0;
}
.close-menu{
  display: none;
}
@media only screen and (max-width: 767px) {
  .mobile-menu{
    display: inline-block;
    width: 40px;
    height: 35px;
  }
  .ham-menu-bar{
    width: 100%;
    height: 3px;
    background-color: #000;
    margin-bottom: 10px;
  }
  .mobile-menu, .header-logo{
margin : 15px 0 0 15px;
  }
  .header-container {
    padding: 0;
  }
  .header-menus{
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100vh);
    background-color: #fff;
    display: none;
  }
  .close-menu{
    display: block;
  }
  .header-logo {
    vertical-align: text-bottom;
    margin-left: 25px;
  }
  .header-menus.show-menu{
    display: block;
    z-index: 1;
  }
  .close-menu{
    position: absolute;
    top: 15px;
    right: 12px;
    width: 30px;
    height: 30px;
  }
  .close-menu img{
    width: 100%;
  }
  .header-nav{
    height: 100%;
    padding: 45px 20px 40px 57px;
  }
  .header-menus li{
    display: block;
    font-size: 26px;
    margin-bottom: 41px;
  }
}
